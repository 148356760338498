import axios from 'axios'

import pagina from "@/class/pagina";

export class Escola {
  static async obtemTodos () {
    return axios.get('/escola',{
      //cache
      headers: {
        'cache-control': 'max-age=3600',
      }
    })
  }
  static async obtemTodosPG (n) {
    const routePg = pagina.routePg;

    return axios.get('/escola/pg?page='+n+routePg);
  }
  static async obtemTodosCoordenacao (payload) {
    return axios.get('/escola/servidor/'+payload.servidor_id+'/coordenacao/'+payload.tipo+'/'+payload.ano)
  }
  static async obtemUm (id) {
    return axios.get('/escola/'+id)
  }
  static async obtemInfoEscola (id,ano) {
    return axios.get('/escola/info/'+id+'/'+ano,{
      //cache
      headers: {
        'cache-control': 'max-age=3600',
      }
    })
  }
  static async cadastrar (payload) {
    try{
      const res = axios.post('/escola', payload)
      return res;
    }catch(e) {
      // console.log(e);
    }
  }
  static async alterar (payload) {
    return axios.put('/escola/'+payload.id, payload)
  }
  static async remover (id) {
    return axios.delete('/escola/'+id)
  }
  static async pesqEscolaCompleto (payload) {
    return axios.get('/escola/pesquisa/'+payload+'/completa')
  }

}

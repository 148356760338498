<template>
    <div class="row" >
      <div class="col-md-12">
        <va-modal
            v-model="showModal"
            hide-default-actions
            overlay-opacity="0.2"
            >
            <template #header>
                <h2>Ano Letivo Desatualizado</h2>
            </template>
            <slot>
                <div>
                    É Necessário Fechar o Ano antigo para Continuar!
                    Acesse o calendário letivo e aperte o botão fechar ano Letivo.
                </div>
            </slot>
            <template #footer>
                <va-button @click="IrCalendario" color="danger">
                   Ir ao Calendário
                </va-button>
            </template>
        </va-modal>
      </div>
    </div>    
</template>
<script>
import { Calendario } from "@/class/calendario.js";

export default {
  name: 'alertaAno',
  data () {
    return {
      showModal: false,
      item:[],
      ano:0,
    }
  },
  methods:{
    IrCalendario(){
       this.$router.push({name: 'calendario'});
    }
  },
  async beforeMount(){
    try {
        let data = await Calendario.calendarioAtual();
        this.item = data.data;

        this.ano = this.item.ano;

        if(this.ano != null){
            var today = new Date();
            var anoAtual = today.getFullYear();
            if(anoAtual == this.ano){
                this.showModal = false;
            }else{
                this.showModal = true;
            }
        } 
    } catch (e) {
        
    }
  }
};  
</script>  